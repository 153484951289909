<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar
      v-bind:secondTierLoadingDone="true"/>
    <br>
    <b-container fluid="true">
      <b-row>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
        <b-col class="col-12 col-sm-12 col-md-10 col-lg-8">
          <b-card class = "mt-5">
            <div v-html="addin" class="mt-1"></div>
          </b-card>
        </b-col>
        <b-col class="col-0 col-sm-0 col-md-1 col-lg-2"></b-col>
      </b-row>
    </b-container>
    </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
// @ is an alias to /src
import TopNavBar from '../components/TopNavBar'
import GenderAppFooter from '../components/Footer'
import { SiteManagerService } from '../services/siteManagerService'

export default {
  name: 'Addin',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.description
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.canonicalUrl
        },
        {
          name: 'twitter:title',
          vmid: 'twitter:title',
          content: this.title
        },
        {
          name: 'twitter:description',
          vmid: 'twitter:description',
          content: this.description
        }
      ],
      link: [
        { rel: 'canonical', href: this.canonicalUrl }
      ]
    }
  },
  components: {
    GenderAppFooter,
    TopNavBar
  },
  data () {
    return {
      addin: '',
      title: 'Office Add-in · gender app',
      description: 'Lade das gratis Word-Add-in hier herunter',
      canonicalUrl: 'https://genderapp.org/add-in'
    }
  },
  methods: {
    getSiteManager () {
      SiteManagerService.getListViewData('', 'all')
        .then((response) => {
          this.addin = response.data.results[0].addin
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  mounted () {
    this.getSiteManager()
  }
}
</script>
